import Page from "../page/";
import _get from "lodash.get";

export default class Faqs extends Page {
  get categories() {
    const body = this.getData("body");
    if (Array.isArray(body)) {
      return body.map(slice => {
        const key = "primary.faq_category";
        return _get(slice, key, null);
      });
    }
    return null;
  }
  get slices() {
    return this.getData("body");
  }
  get faqs() {
    this.getData("body");
  }
  get categories() {
    const body = this.getData("body");
    if (Array.isArray(body)) {
      return body.map(slice => {
        const key = "primary.faq_category";
        return _get(slice, key, null);
      });
    }
    return null;
  }
}
