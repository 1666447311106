import React, { useState } from "react";
import {
  Grid,
  Row,
  Col,
  BlockLink,
  Heading,
  Stack,
  Icon,
  Box,
  Text,
  Link,
  Hide
} from "pws-design-system/design-system";
import RichText from "../../common/components/rich-text";
import PageLayout from "../../common/layouts/PageLayout";
import FaqsModel from "../../../models/prismic/faqs/";
import _isNil from "lodash.isnil";
import Scrollspy from "react-scrollspy";
import DropdownSelect from "../../common/components/dropdown-select/DropdownSelect";
import usePreviewDataStore from "../../common/hooks/stores/usePreviewDataStore";
import _get from "lodash.get";
import { isBrowser } from "../../../utils/";
import { kebabCase } from "lodash";
import { ThemeContainer } from "../../common/hooks/useTheme";
import { Providers } from "../../common/layouts/Layout";

function processLinkTitle(title) {
  return encodeURIComponent(kebabCase(title.toLowerCase()));
}

function Faq({ item, hash, ...rest }) {
  const [isActive, setIsActive] = useState(hash && processLinkTitle(item.faq_question) === hash);

  function handleClick(event) {
    history.replaceState({}, "", `#${event.target.name}`);
    setIsActive(!isActive);
  }

  return (
    <Stack isInline {...rest}>
      <Box width="30px">
        <Box
          mt={1}
          position="relative"
          width="20px"
          height="20px"
          bg="brand.green.base"
          rounded="full"
          onClick={handleClick}
          cursor="pointer"
        >
          <Icon
            name={isActive === true ? "minus" : "add"}
            position="absolute"
            top="50%"
            left="50%"
            transform="translateX(-6px) translateY(-6px)"
            width="12px"
            height="12px"
            color="white"
          />
        </Box>
      </Box>
      <Box flex="1">
        <BlockLink
          name={processLinkTitle(item.faq_question)}
          mb={3}
          fontWeight="bold"
          lineHeight="tall"
          onClick={handleClick}
        >
          {item.faq_question}
        </BlockLink>

        {isActive === true && (
          <Box
            pl={3}
            py={2}
            mb={4}
            lineHeight="tall"
            borderLeftColor="brand.green.base"
            borderLeftWidth="4px"
            borderLeftStyle="solid"
          >
            <RichText
              markup={item.faq_answer.raw}
              serializerConfig={{
                paragraph: children => (
                  <Text mb={0} variant="callout" lineHeight="tall">
                    {children}
                  </Text>
                )
              }}
            />
          </Box>
        )}
      </Box>
    </Stack>
  );
}

function FaqsDisplay({ pageContext }) {
  const { theme } = ThemeContainer.useContainer();
  const [previewData] = usePreviewDataStore.useContainer();
  const data =
    previewData === null ? pageContext.data : _get(previewData, "pageData.prismicFaqs.data");
  const faqsModel = new FaqsModel({ data: data });
  let initialActiveCategory;
  const faqsByCat = faqsModel.slices.reduce((prev, curr) => {
    prev[curr.primary.faq_category] = curr.items.map(item => processLinkTitle(item.faq_question));
    return prev;
  }, {});
  if (isBrowser() === false) {
    initialActiveCategory = faqsModel.categories[0];
  } else {
    if (_isNil(faqsModel.categories) === true) {
      initialActiveCategory = null;
    } else if (_isNil(window.location.hash) === true || window.location.hash === "") {
      initialActiveCategory = faqsModel.categories[0];
    } else {
      const hash = decodeURIComponent(window.location.hash.substr(1));
      if (faqsModel.categories.includes(hash)) {
        initialActiveCategory = hash;
      } else {
        const activeCategory = Object.keys(faqsByCat).filter(key => faqsByCat[key].includes(hash));
        if (activeCategory.length === 1) {
          initialActiveCategory = activeCategory[0];
        }
      }
    }
  }

  const [activeCategory, setActiveCategory] = useState(initialActiveCategory);

  function handleClick(category) {
    setActiveCategory(category);
  }

  function handleUpdate(element) {
    if (!element) {
      return;
    }
    setActiveCategory(element.id);
  }

  function handleSelect(category: string) {
    setActiveCategory(category);
    window.location.hash = category;
  }

  const titleLinks = [];
  faqsModel.slices.forEach(slice => {
    slice.items.forEach(item => {
      titleLinks.push(processLinkTitle(item.faq_question));
    });
  });

  return (
    <PageLayout
      page={faqsModel}
      metaTitle={faqsModel.metaTitle}
      metaDescription={faqsModel.metaDescription}
      headerProps={{ bg: theme.colors.bg.base.primary, context: "page" }}
      containerProps={{ bg: theme.colors.bg.base.primary }}
      contentProps={{ bg: theme.colors.bg.base.primary }}
    >
      <Grid>
        <Row justify="space-between">
          {faqsModel.has("title") && (
            <>
              <Col sm={12}>
                <Heading as="h1" mb={[4, null, 5]} variant="hero">
                  Frequently Asked Questions
                </Heading>
              </Col>
              <Col sm={12} lg={3}>
                <Hide lg xl xxl>
                  {faqsModel.has("categories") && (
                    <DropdownSelect
                      initialActiveItem={{
                        text: initialActiveCategory,
                        key: initialActiveCategory
                      }}
                      items={faqsModel.categories.map(category => {
                        return {
                          key: category,
                          text: category
                        };
                      })}
                      selectHandler={handleSelect}
                    />
                  )}
                </Hide>
                <Box mt={4} position="sticky" top="60px">
                  <Hide xs sm>
                    <Scrollspy
                      items={[...faqsModel.categories, ...titleLinks]}
                      onUpdate={handleUpdate}
                      componentTag="div"
                    >
                      {faqsModel.has("categories") &&
                        faqsModel.categories.map(category => (
                          <BlockLink
                            width="100%"
                            display="inline-block"
                            href={`#${category}`}
                            fontSize="xl"
                            fontWeight={activeCategory === category && "bold"}
                            onClick={() => handleClick(category)}
                            mb={[2, null, null, 3]}
                          >
                            {category}
                            {activeCategory === category && (
                              <>
                                {" "}
                                <Icon
                                  name="arrow-right"
                                  mx="6px"
                                  size="0.8em"
                                  color="brand.green.base"
                                />
                              </>
                            )}
                          </BlockLink>
                        ))}
                    </Scrollspy>
                  </Hide>
                </Box>
              </Col>
              <Col sm={12} lg={8}>
                <Box mb={[4, null, 6]}>
                  <Hide lg xl xxl>
                    {faqsModel.has("slices") &&
                      faqsModel.slices
                        .filter(slice => slice.primary.faq_category === activeCategory)
                        .map(slice => (
                          <div>
                            {slice.items.map((item, i) => (
                              <Faq
                                hash={
                                  isBrowser() && decodeURIComponent(window.location.hash.substr(1))
                                }
                                item={item}
                                mt={i !== 0 && 2}
                                mb={i === slice.items.length - 1 && [3, null, 5]}
                              />
                            ))}
                          </div>
                        ))}
                  </Hide>
                  <Hide xs sm>
                    {faqsModel.has("slices") &&
                      faqsModel.slices.map(slice => (
                        <div id={slice.primary.faq_category}>
                          <Link name={slice.primary.faq_category} mb={4}></Link>
                          <Heading as="h2" mb={4}>
                            {slice.primary.faq_category}
                          </Heading>
                          {slice.items.map((item, i) => (
                            <Faq
                              hash={
                                isBrowser() && decodeURIComponent(window.location.hash.substr(1))
                              }
                              item={item}
                              mt={i !== 0 && 3}
                              mb={i === slice.items.length - 1 && [3, null, 5]}
                            />
                          ))}
                        </div>
                      ))}
                  </Hide>
                </Box>
              </Col>
            </>
          )}
        </Row>
      </Grid>
    </PageLayout>
  );
}

export default function Faqs(props) {
  return (
    <Providers>
      <FaqsDisplay {...props}>{props.children}</FaqsDisplay>
    </Providers>
  );
}
